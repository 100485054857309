import React, { useEffect,useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../components/button'
import { Container } from '../components/container'
import { LocalizedLink } from '../components/localized-link'

const ThankYou = () => {
  const { t } = useTranslation()
  const getFormName = localStorage.getItem('FormName')
  const parsedData = JSON.parse(localStorage.getItem('Calculation-data'))
  const getConversationData = JSON.parse(localStorage.getItem('conversation'))
  const parsedOnlineData = JSON.parse(localStorage.getItem('order-info'))
  const isWeekLength6 =
    parsedOnlineData && parsedOnlineData.isOrderFoodWeekMeal5DaysLength
      ? '5'
      : '6'
  const isPaymentOnline =
    parsedOnlineData && parsedOnlineData.onlinePayment
      ? 'online-card'
      : 'faktura'
  const deliveryTimeRange =
    parsedOnlineData &&
    parsedOnlineData.deliveryTime &&
    `${parsedOnlineData.deliveryTime[0]}-${parsedOnlineData.deliveryTime[1]}`

  const pushDataLayerEvent = formName => {
    if (formName === 'vege-objednavka') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_vegetarian_menu_order',
          pageURL: '/thank-you',
        })
    } else if (formName === 'hromadna-objednavka') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_multi_order',
          pageURL: '/thank-you',
        })
    } else if (formName === 'hromadna-objednavka_en') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_multi_order',
          pageURL: '/thank-you',
        })
    } else if (formName === 'vege-objednavka_en') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_vegetarian_menu_order',
          pageURL: '/thank-you',
        })
    } else if (formName === 'konzultace-objednavka') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_nutritionist_consultation',
          pageURL: '/thank-you',
        })
    } else if (formName === 'konzultace-dietolog') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_nutritionist_consultation',
          pageURL: '/thank-you',
        })
    } else if (formName === 'konzultace-dietolog_en') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_nutritionist_consultation',
          pageURL: '/thank-you',
        })
    } else if (formName === 'darkovy-certifikat') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_gift_voucher',
          pageURL: '/thank-you',
        })
    } else if (formName === 'conversion-callback') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_callback',
          pageURL: '/thank-you',
        })
    } else if (formName === 'demo-objednavka') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_demo_order',
          pageURL: '/thank-you',
        })
    } else if (formName === 'demo-objednavka_en') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_demo_order',
          pageURL: '/thank-you',
        })
    } else if (formName === 'order-call') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_callback',
          pageURL: `/thank-you/price_daily=${getConversationData.price}&&program=${getConversationData.program}&&program_length=${getConversationData.lenght}&&meal_count
        =${getConversationData.numberOfMeals}&&person_count=${getConversationData.person}&&testovani=${getConversationData.testovani}&&coupon=${getConversationData.promo}&&kcal_count=${getConversationData.kalorie}&&menu_choice=${getConversationData.menu}&&sex_choice=${getConversationData.sex}&&week_length=${getConversationData.weekLenght}`,
          price_daily: getConversationData.price_daily,
          program: getConversationData.program,
          program_length: getConversationData.lenght,
          meal_count: getConversationData.numberOfMeals,
          person_count: getConversationData.person,
          testovani: getConversationData.testovani,
          coupon: getConversationData.promo,
          kcal_count: getConversationData.kalorie,
          menu_choice: getConversationData.menu,
          sex_choice: getConversationData.sex,
          week_length: getConversationData.weekLenght,
        })
    } else if (formName === 'kalkulacka') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_calculator',
          pageURL: `/thank-you/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
        =${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`,
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        })
    } else if (formName === 'kalkulacka_en') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_calculator',
          pageURL: `/thank-you/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
        =${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`,
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        })
    } else if (formName === 'online-order') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: 'conversion_online_order',
          pageURL: `/thank-you/price_daily=${parsedOnlineData.price}&&program=${parsedOnlineData.Program}&&program_length=${parsedOnlineData.length}&&meal_count=${parsedOnlineData.numberOfMeals}&&person_count=${parsedOnlineData.NumberOfPeople}&&testovani
                  =${parsedOnlineData.testovani}&&coupon=${parsedOnlineData.promo}&&kcal_count=${parsedOnlineData.kcal}&&menu_choice=${parsedOnlineData.CustomerMenu}&&sex_choice=${parsedOnlineData.CustomerGender}&&week_length=${isWeekLength6}&&company_order=${parsedOnlineData.isCompanyOrder}&&city=${parsedOnlineData.city}&&payment_method=${isPaymentOnline}&&delivery_time_range=${deliveryTimeRange}&&price=${parsedOnlineData.price}&&discount=${parsedOnlineData.PriceDiscount}`,
          price_daily: parsedOnlineData.price_daily,
          program: parsedOnlineData.Program,
          program_length: parsedOnlineData.length,
          meal_count: parsedOnlineData.numberOfMeals,
          person_count: parsedOnlineData.NumberOfPeople,
          testovani: parsedOnlineData.testovani,
          coupon: parsedOnlineData.promo,
          kcal_count: parsedOnlineData.kcal,
          menu_choice: parsedOnlineData.CustomerMenu,
          sex_choice: parsedOnlineData.CustomerGender,
          week_length: isWeekLength6,
          company_order: parsedOnlineData.isCompanyOrder,
          city: parsedOnlineData.city,
          payment_method: isPaymentOnline,
          delivery_time_range: deliveryTimeRange,
          price: parsedOnlineData.price,
          discount: parsedOnlineData.PriceDiscount,
        })
    } else {
      window.dataLayer = window.dataLayer || []
    }
  }

  

  useEffect(() => {
    pushDataLayerEvent(getFormName)
  }, [getFormName])
  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    window.dataLayer &&
      window.dataLayer.push({
        event: 'purchase',
        pageURL: window.location.pathname,
      })
  }, [])

  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  
  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState("")
  const webVersions = ['new-calc-A', 'new-calc-B']
  
  useEffect(() => {
    const curVersion = localStorage.getItem("website-version")
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem("website-version", randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])
  
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
    window.dataLayer.push({
      pageURL: "/thank-you",
      testovani: websiteVersion,
      event: "ga.pageview"

    })
  }, [])

  return (
    <>
      <Container className="text-center">
        <h1 className="text-center" style={{ marginTop: '100px' }}>
          {t('forms.TY.titlePart1')}
          <br />
          <span style={{ color: `var(--color-green)` }}>
            {' '}
            {t('forms.TY.titlePart2')}
          </span>
        </h1>
        <Button type="primary">
          <LocalizedLink to="/"> {t('forms.TY.linkBack')}</LocalizedLink>
        </Button>
        <div style={{ marginBottom: '100px' }} />
      </Container>
    </>
  )
}

export default ThankYou
